import React from "react";
import tw, { styled } from "twin.macro";
import _ from "lodash";

import ScheduleListGameItem from "./ScheduleListGameItem";

import ScheduleListEventItem from "./ScheduleListEventItem";

const ListContainer = styled.div`
  ${tw`w-full flex flex-col pb-8`}
`;

const ScheduleList = ({
  eventAndGames = [],
  leagues = [],
  teamsByName,
  homeGameColor,
  awayGameColor,
  promoGameColor,
  timezone,
  color,
  site,
  homeTeams,
  currentMonth = "",
}) => {
  return (
    <ListContainer>
      {!_.isEmpty(eventAndGames) ? (
        eventAndGames.map((item, idx) => {
          const { mdType } = item.node.frontmatter;
          const isEvent = mdType === "event";
          if (isEvent) {
            return <ScheduleListEventItem event={item} />;
          } else {
            const league = leagues.find(
              (l) =>
                (l.node.frontmatter.name = item.node.frontmatter.competition)
            );
            return (
              <ScheduleListGameItem
                key={item.node.id}
                game={item}
                league={league}
                teamsByName={teamsByName}
                timezone={timezone}
                homeGameColor={homeGameColor}
                awayGameColor={awayGameColor}
                promoGameColor={promoGameColor}
                site={site}
                color={color}
                homeTeams={homeTeams}
              />
            );
          }
        })
      ) : (
        <div>No Games in {currentMonth}</div>
      )}
    </ListContainer>
  );
};

export default ScheduleList;
